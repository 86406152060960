.exportsContainer {
  margin: 20px 10px 00px 20px;
  padding: 10px;
  background: #cdd2d8;
  border-radius: 10px;
  width: 450px;
  box-shadow: 0px 5px 15px;
  z-index: 1;
  position: absolute;
  top: 15%;
  left: 10%;
}

.modalBackground {
  z-index: 1;
  width: 100vw;
  height: 100%;
  background-color: rgba(100, 100, 100, 0.5);
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.exportsPublishForm {
  display: flex;
  flex-direction: row;
  height: auto;
  align-items: center;
}

.exportsPublishSent {
  display: flex;
  flex-direction: column;
  height: auto;
  align-items: center;
  justify-content: flex-start;
  margin: 5px;
}

.formGroup {
  flex-direction: row;
  display: flex;
  justify-content: center;
}

.textInputGroup {
  display: flex;
  flex-direction: column;
  margin: 0px 10px;
}

.textInputSet {
  justify-content: space-between;
  display: flex;
  align-items: center;
  flex-direction: column;
  padding-bottom: 10px;
  height: 50px;
}

.buttonSet {
  display: flex;
  flex-direction: column;
}

.idInputLabel {
  color: black;
  font-weight: bold;
}

.filterInput {
  display: flex;
  flex-direction: column;
  margin: 0px 10px;
  height: 40px;
}

.exportsTable {
  padding: 20px;
}

.exportSubmitBtn {
  margin: 6px;
}

.submittedText {
  margin-bottom: 10px;
}

.exportsIdInputGrp {
  height: auto;
}

.exportsIdInput {
  width: 300px;

  border-radius: 0%;
  border: black 1px solid;
  background-color: #eeeff2;
  color: rgba(0, 0, 0, 255);
}

input#exportsId {
  background-color: #eeeff200;
}

.exportsIdInput:focus,
.exportsIdInput:hover {
  background: #eeeff2;
}

.exportsIdInput::placeholder {
  color: darkgray;
  opacity: 1;
}

.filterInput ::placeholder {
  color: black;
  opacity: 0.5;
}

#content-header.exportsHeader {
  min-height: 50px;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  display: flex;
}

#map-exports-container {
  background: #e2e3e6;
  border-radius: 4px !important;
  box-shadow: 0 6px 8px rgba(0, 0, 0, 0.15) !important;
  /* margin: 20px !important; */
  padding: 0 10px 10px 10px;
  width: -webkit-fill-available;
  overflow-y: scroll;
  height: 75vh;
}

.newUploadButton {
  margin: 20px;
  margin-bottom: 0px;
  width: 250px;
}

.tableLabel {
  font-size: large;
  font-weight: bolder;
  margin: 20px 20px 0px 20px;
}

#exports-content-outer-wrapper {
  background: #afb5bf;
  position: absolute;
  top: 0px !important;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  z-index: 900;
  overflow: hidden;
  min-height: 480px;
}

#exports-content-wrapper {
  padding: 25px;
  position: relative;
  display: flex;
  flex-flow: column nowrap;
  margin: 0;
  min-height: 100%;
  width: 100%;
  border: none;
  box-shadow: none;
  overflow: hidden;
}

.existingCaseList {
  margin: 10px 20px 0px 20px;
  cursor: pointer;
  width: 30%;
}

.existingCaseList li {
  font-size: small;
}

.exportsPageNavButton {
  margin-left: 20px;
  width: fit-content;
}

.exportsHeaderRight input {
  border-color: #778394;
}

#sidebar {
  background: #252b33;
}

.paginationElement {
  padding: 0px 15px;
  text-align: center;
  margin-right: 10px;
}

option {
  background-color: #252b33;
  color: white;
}

.exportEventOfficerLabel {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
}

.searchButton {
  margin-top: 10px;
}

.loadNextLink {
  display: flex;
  align-items: center;
  flex-direction: row;
}

h4 {
  color: black;
}

.icon-plus {
  margin-right: 10px;
  margin-top: 2px;
  color: black;
}

#refreshButton {
  /* margin-left: 20px; */
  width: 3em;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
}

#rowRefreshButton {
  padding-left: 10px;
  display: flex;
  align-items: center;
}

#status {
  display: flex;
  max-width: 20em;
  white-space: normal;
  align-items: center;
  justify-content: space-between;
  line-height: normal;
}

#statusTd {
  padding-right: 15px;
}
