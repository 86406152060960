.caseContainer {
  margin: 20px 10px 00px 20px;
  padding: 10px;
  background: #cdd2d8;
  border-radius: 10px;
  width: 400px;
  box-shadow: 0px 5px 15px;
  z-index: 1;
  position: absolute;
  top: 15%;
  left: 10%;
}

.modalBackground {
  z-index: 1;
  width: 100vw;
  height: 100%;
  background-color: rgba(100, 100, 100, 0.5);
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.casePublishForm {
  display: flex;
  flex-direction: row;
  height: auto;
  align-items: center;
}

.casePublishSent {
  display: flex;
  flex-direction: column;
  height: auto;
  align-items: center;
  justify-content: flex-start;
  margin: 5px;
}

.formGroup {
  flex-direction: row;
  display: flex;
  justify-content: center;
}

.textInputGroup {
  display: flex;
  flex-direction: column;
  margin: 0px 10px;
}

.textInputSet {
  justify-content: space-between;
  display: flex;
  flex-direction: column;
  padding-bottom: 10px;
}

.buttonSet {
  display: flex;
  flex-direction: column;
}

.idInputLabel {
  color: black;
  font-weight: bold;
}

.filterInput {
  display: flex;
  flex-direction: column;
  margin: 0px 10px;
  height: 40px;
}

.caseTable {
  padding: 20px;
}

.caseSubmitBtn {
  margin: 15px 0px 0px 15px;
}

.submittedText {
  margin-bottom: 10px;
}

.caseNumberInputGrp {
  height: auto;
}

.caseNumberInput {
  width: 215px;
  border-radius: 5%;
  border: black 2px solid;
  background-color: #eeeff2;
  color: rgba(0, 0, 0, 255);
}

input#caseNumber {
  background-color: #eeeff200;
}

.caseNumberInput:focus,
.caseNumberInput:hover {
  background: #eeeff2;
}

.caseNumberInput::placeholder {
  color: darkgray;
  opacity: 1;
}

#content-header.casesHeader {
  min-height: 50px;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  display: flex;
}

#map-cases-container {
  background: #e2e3e6;
  border-radius: 4px !important;
  box-shadow: 0 6px 8px rgba(0, 0, 0, 0.15) !important;
  /* margin: 20px !important; */
  padding: 0 10px 10px 10px;
  width: -webkit-fill-available;
  overflow-y: scroll;
  height: 75vh;
}

.newUploadButton {
  margin: 20px;
  margin-bottom: 0px;
  width: 250px;
}

.tableLabel {
  font-size: large;
  font-weight: bolder;
  margin: 20px 20px 0px 20px;
}

#cases-content-outer-wrapper {
  background: #afb5bf;
  position: absolute;
  top: 0px !important;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  z-index: 900;
  overflow: hidden;
  min-height: 480px;
}

#cases-content-wrapper {
  padding: 25px;
  position: relative;
  display: flex;
  flex-flow: column nowrap;
  margin: 0;
  min-height: 100%;
  width: 100%;
  border: none;
  box-shadow: none;
  overflow: hidden;
}

.existingCaseList {
  margin: 10px 20px 0px 20px;
  cursor: pointer;
  width: 30%;
}

.existingCaseList li {
  font-size: small;
}

.casePageNavButton {
  margin-left: 20px;
  width: fit-content;
}

.caseHistoryHeader {
  display: flex;
  float: row-reverse;
  width: 100%;
  background: #cdd2d8;
}

.caseHistoryHeaderRight {
  display: flex;
}
.caseHeaderRight input {
  border-color: #778394;
}

.caseHistoryHeaderLeft {
  display: flex;
}

.caseHistorySearchMessage {
  display: flex;
  align-items: center;
}
.caseHistorySearchMessage h1 {
  margin-bottom: 5px;
  margin-left: 20px;
}

#sidebar {
  background: #252b33;
}

#content-header-container.caseHistoryHeader {
  background: #cdd2d8;
  display: flex;
  justify-content: space-between;
  flex-direction: row;
}

.paginationElement {
  padding: 0px 5px;
  text-align: center;
}
