/* incidents.css */
.blockView {
  background: none;
  flex-shrink: 1 !important;
  overflow-x: hidden;
  overflow-y: auto;
  height: 100%;
}
.blockView.full {
  box-shadow: none;
  height: 100%;
  margin-bottom: 10px;
}

#main-container {
  background: #252b33;
  position: absolute;
  top: 0px !important;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: hidden;
}

/* Export Form */
.ExportForm {
  padding: 25px;
}
.ExportForm h4,
.ExportForm input,
.ExportForm p {
  margin-bottom: 15px;
}

/* IncidentEventRow */

button.assignment-btn {
  margin: 3px 3px;
  width: 120px;
  text-align: center;
}
button.assignment-btn.unassign {
  background: linear-gradient(to bottom, #a60707 0, #8c0000 100%);
}
button.assignment-btn.playback {
  background: linear-gradient(to bottom, rgb(4, 114, 4), darkgreen 100%);
}

/* Incident Event Search Form */
.scoochOverALittle {
  padding-left: 10px;
}
.top-gray-border {
  border-top: 1px solid #454d59;
}

/* Incident Event Time Search Slider */
.record-start {
  border-bottom-color: darkgreen;
  border-bottom-style: solid;
  border-bottom-width: 2.5px;
  padding-left: 5px;
}
.record-stop {
  border-bottom-color: darkred;
  border-bottom-style: solid;
  border-bottom-width: 2.5px;
}
input[type="range"]::-webkit-slider-runnable-track {
  background: gray;
  border: 1px solid #5d6672;
  cursor: pointer;
  height: 16px;
  width: 100%;
}
input[type="range"]::-webkit-slider-thumb {
  -webkit-appearance: none;
  border: 1px solid #000000;
  border-radius: 3px;
  cursor: pointer;
  height: 30px;
  margin-top: -8px;
  width: 12px;
}
input#startTimeOffsetInMinutes,
input#stopTimeOffsetInMinutes {
  -webkit-appearance: none;
  border: none;
  cursor: pointer;
  height: 36px;
  margin-top: 0;
  padding: 0 0;
  width: 35%;
}
input[type="range"]#stopTimeOffsetInMinutes::-webkit-slider-thumb {
  background: darkred;
}
input[type="range"]#startTimeOffsetInMinutes::-webkit-slider-thumb {
  background: darkgreen;
}
#slider-container {
  margin-top: 10px;
}
#event-representation {
  background: repeating-linear-gradient(
    -55deg,
    #222,
    #222 5px,
    #333 5px,
    #333 10px
  );
  background-position: top right;
  border-bottom: 1px solid #5d6672;
  border-top: 1px solid #5d6672;
  box-shadow: 1px 1px 1px #000000, 0 0 1px #0d0d0d;
  content: "EVENT";
  display: inline-block;
  height: 16px;
  margin-bottom: 10px;
  min-width: 10px;
  width: 30%;
}
.right-inner-addon i {
  padding: 8px 5px;
  pointer-events: none;
  position: absolute;
}
.right-inner-addon input {
  padding-left: 27px;
  padding-right: 0;
}

/* Incident Response Row */
.padding-shrink {
  padding-left: 5px !important;
  transition: padding-left 0.2s linear;
}

/* Incident Row*/

#create-case-button {
  width: 10em;
}

.bold {
  font-weight: bolder;
}
.incident-row {
  height: 40px !important;
}
section.incidentSelected {
  margin-bottom: 0px !important;
}
.selected-indicator.incidentSelected {
  display: block;
  width: 5px !important;
}
.selected-indicator {
  background: #e5a800;
  border-radius: 4px 0 0 4px;
  bottom: 0;
  display: none;
  position: absolute;
  top: 0;
  transition: width 0.2s;
  width: 0;
  z-index: 5;
}
.response-table {
  animation: events;
  animation-duration: 5s;
  background: #e2e3e6 !important;
  border-radius: 4px !important;
  /*border-top: 4px solid #cdd2d8; */
  box-shadow: 0 6px 8px #000000 !important;
  box-shadow: 0 6px 8px rgba(0, 0, 0, 0.15) !important;
  margin: 4px 0 5px 10px !important;
  overflow: visible;
  width: calc(100% - 12px);
}
@keyframes events {
  from {
    max-height: 0;
    min-height: 0;
  }

  to {
    max-height: 100%;
  }
}

#event-table,
#event-table tr:nth-last-child(1) td:nth-child(1),
#event-table tr:nth-last-child(1) td:nth-last-child(1) {
  border-radius: 4px;
}

.response-table * td,
#event-table * td {
  color: #5d6672;
  font-size: 13px;
  font-weight: 600;
  line-height: 36px;
  padding: 0 10px 0 10px;
  text-align: center;
}
.response-table * td.eventcriteria {
  color: #5d6672;
  background: #ced0d639;
  font-size: 13px;
  font-weight: 600;
  line-height: 36px;
  padding: 0 10px 0 10px;
}
.response-table * th,
#event-table * th {
  color: #5d6672;
  padding: 0 10px 0 10px;
  text-align: center;
}

.response-table * th.eventcriteria {
  color: #5d6672;
  background: rgba(195, 204, 221, 0.4);
  padding: 0 10px 0 10px;
}

.response-table tr.responseSelected,
.response-table tr.responseSelected:hover {
  background-color: #1f7e01 !important;
  background-color: rgba(31, 126, 1, 0.1) !important;
  border-color: rgb(31, 126, 1);
  border-style: solid;
  border-width: 0 0 0 10px;
  transition: border-width 0.2s linear;
}
.response-table tbody tr:nth-of-type(even) {
  background: #e2e3e6;
}
.response-table tbody tr:nth-of-type(even):hover {
  background: #dcdee0;
}
.response-table tbody tr:nth-of-type(odd):hover {
  background: #dbdcdf;
}
.response-table tbody tr:nth-of-type(odd) {
  background: #eeeff2;
}
.col-sml {
  width: 9%;
}
.col-med {
  width: 11%;
}
.col-big {
  width: 13%;
}
.col-dt {
  width: 15%;
}

/* Search */
::-webkit-scrollbar-thumb:vertical:hover {
  background-color: #dbdcdf !important;
}

::-webkit-scrollbar-thumb {
  background-color: #eeeff2;
  border: 1px solid #777f8c;
  padding-left: 5px;
}

/* SearchResults */
#content-outer-wrapper {
  top: 50px !important;
}

#content-header-container {
  background: #cdd2d8;
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
}

/* Dropdown Button */
.dropbtn {
  background-color: #252b33;
  color: #e5dedd;
  padding: 12px;
  font-size: 16px;
  border: none;
}

/* The container <div> - needed to position the dropdown content */
.dropdown {
  position: relative;
  display: inline-block;
}

/* Dropdown Content (Hidden by Default) */
.dropdown-content {
  display: none;
  position: absolute;
  background-color: #5d6672;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
  overflow: visible;
  border-radius: 0 5px 5px 5px;
}

/* Links inside the dropdown */
.dropdown-content a {
  display: block;
  min-height: 40px;
  white-space: nowrap;
  font-weight: 600;
  font-size: 14px;
  line-height: 40px;
  color: #dde1e5;
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
  position: relative;
  padding: 0 15px;
  margin: 0;
  list-style: none;
  cursor: pointer;
  text-align: left;
}

.dropdown-content a.top {
  border-radius: 0 5px 0 0;
}

.dropdown-content a.bottom {
  border-radius: 0 0 5px 5px;
}
/* Change color of dropdown links on hover */
.dropdown-content a:hover {
  background-color: #252b33;
}

/* Show the dropdown menu on hover */
.dropdown:hover .dropdown-content {
  display: inline;
}

/* Change the background color of the dropdown button when the dropdown content is shown */
.dropdown:hover {
  background: #252b33;
  color: #dde1e5;
  border-radius: 5px 0 0 5px;
}

.paginationControl {
  display: inline-flex;
  align-items: center;
  height: 50px;
  font-size: 16px;
}

.pagination {
  padding: 8px 5px;
  margin-top: 2px;
}

.paginationInput {
  text-align: center;
  padding: 12px !important;
}
.paginationControl select {
  width: 150px;
}

.pageChangeArrows {
  margin-right: 15px;
  margin-left: 10px;
}
